<template>
  <div class="calendar-generator">
    <h1>Generador de link para iframe de calendario</h1>
    <div class="form-group">
      <label for="color">Color del texto</label>
      <input id="color" type="text" v-model="color" />
    </div>
    <div class="form-group">
      <label for="background">Color de los fondos</label>
      <input id="background" type="text" v-model="background" />
      <div class="form-group"></div>
      <label for="logo-path">Link de la imagen</label>
      <input id="logo-path" type="text" v-model="logoPath" />
    </div>
    <br />
    <button @click="generate">Generar link</button>
    <button @click="toggleVisibility">{{ isPreviewVisible ? 'Ocultar ' : 'Mostrar ' }}vista previa</button>
    <div class="form-group">
      <input type="text-area" readonly v-model="result" />
    </div>
    <iframe v-if="isPreviewVisible" :src="result" frameborder="0" width="100%" height="600px" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      color: '',
      background: '',
      logoPath: '',
      // host: 'https://bets.golstats.com',
      host: 'http://localhost:8081',
      result: '',
      isPreviewVisible: false,
    };
  },
  methods: {
    generate() {
      let path = '?';
      if (this.color) {
        path += `color=${escape(this.color)}&`;
      }
      if (this.background) {
        path += `background=${escape(this.background)}&`;
      }
      if (this.logoPath) {
        path += `logo=${escape(this.logoPath)}&`;
      }
      this.result = `${this.host}/calendar${path}`;
    },
    toggleVisibility() {
      this.isPreviewVisible = !this.isPreviewVisible;
    },
  },
};
</script>

<style lang="scss">
.calendar-generator {
  & .form-group {
    label {
      display: block;
    }
    input[type='text-area'] {
      margin-top: 40px;
      padding: 1rem;
      width: 75%;
    }
  }
  iframe {
    height: 600px !important;
  }
}
</style>
